<template>
  <div class="p-1">
     <div-table :config="configList" :idWharhouse="idWharhouse" :currentUser="currentUser" :data="warehouses" @reset="reset"  @refresh="refresh" @filtrer="filtrer" @search="search" :loading="loading" :paginate="paginate" @paginatation="paginatation"></div-table>
  </div>
</template>

<script>
import config from './config'
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
       configList: config.list,
       loading:false,
       content: {},
       warehouses:[],
      // currentUser: {},
       limit: 50,
       paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
       },
    };
  },
  async mounted() {
    //await this.getUser();
    this.getWarehouses({ limit: this.limit });
    console.log(this.content)
  },

  filters: {},
  methods: {
    async getWarehouses(filters) {
      filters.all=true;
      const res = await this.$server.search("warehouses", filters);
      if (res.content.results) {
        this.warehouses = res.content.results;
        this.warehouses.forEach(function (warehouse) {warehouse.zones = this.ZonesNames(warehouse.zones)}.bind(this));
        this.warehouses=this.sortByAttribute(this.warehouses, 'country', '_id')
        this.warehouses.total = res.content.total;
        this.warehouses.lastpage = res.content.last_page;
        this.warehouses.per_page = res.content.per_page;
        this.warehouses.currentpage = res.content.current_page;
      } else{ this.warehouses = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}}
    },
    ZonesNames(data){
      let zone="";
      for (let i in data) {
        zone+= '<a class="text-blue-600" target="_blank" href="/zones/edit/'+data[i]._id+'">'+data[i].name+'</a>'
        if(data.length-1 != i) zone+=" ,"; 
      }
      return zone;
    },
    sortByAttribute(array, ...attrs) {
          // generate an array of predicate-objects contains
          // property getter, and descending indicator
          let predicates = attrs.map(pred => {
            let descending = pred.charAt(0) === '-' ? -1 : 1;
            pred = pred.replace(/^-/, '');
            return {
              getter: o => o[pred],
              descend: descending
            };
          });
          // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
          return array.map(item => {
            return {
              src: item,
              compareValues: predicates.map(predicate => predicate.getter(item))
            };
          })
          .sort((o1, o2) => {
            let i = -1, result = 0;
            while (++i < predicates.length) {
              if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
              if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
              if (result *= predicates[i].descend) break;
            }
            return result;
          })
          .map(item => item.src);
    },

    async refresh() {
      this.getWarehouses({ limit: this.limit });
    },

    /*********************************************************************************/
    async reset(data) {
      const filters = {
        limit: this.limit,
      };
      await this.getWarehouses(filters);
    },
    async search(data) {
      const search = {
        name: data,
      };
      const res = await this.$server.find("warehouses", search);
      console.log('resuult',res)
      if (res.content) {
        this.warehouses = res.content;
        this.warehouses.forEach(function (warehouse) {warehouse.zones = this.ZonesNames(warehouse.zones)}.bind(this));
        this.warehouses=this.sortByAttribute(this.warehouses, 'country', '_id')
        this.warehouses.total = res.content.total;
        this.warehouses.lastpage = res.content.last_page;
        this.warehouses.per_page = res.content.per_page;
        this.warehouses.currentpage = res.content.current_page;
      } else {this.warehouses = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}}
    },
    async filtrer(data) {
      console.log(data);
      const filters = {
        name: data.name,
        limit: this.limit,
      };
       await this.getWarehouses(filters);
    },
    /****************************************************************************/
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
      console.log(this.currentUser);
    },
    /***************************************************************************************/
    async paginatation(direction){
      var page=0;
      if(direction == "next"){
         page=++this.paginate.currentpage;
      }else{
         page=--this.paginate.currentpage;
      }
       const filters = {
          page: page,
          limit: this.limit,
      };
      this.getWarehouses(filters);
    },
  },
};
</script>

<style>
</style>




